<template>
  <el-dialog
    :title="$t('editReservationDialog.title')"
    :visible="true"
    :close-on-click-modal="false"
    :width="width"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem v-if="!formData.isMember" :label="isPubApt ? $t('orderDetailBlock.visitor.name.title') : $t('orderDetailBlock.user.name.title')" prop="userName">
        <BaseElInput v-model="formData.userName" :placeholder="$t('branchSetting.dialog.branchName.placeholder')" :disabled="isPubApt" />
      </BaseElFormItem>
      <BaseElFormItem v-if="!formData.isMember" :label="isPubApt ? $t('editReservationDialog.visitor.phone.title') : $t('editReservationDialog.user.phone.title')" prop="userPhone">
        <BaseElInput v-model="formData.userPhone" :placeholder="$t('editReservationDialog.user.phone.placeholder')" :disabled="isPubApt" />
      </BaseElFormItem>
      <BaseElFormItem v-if="checkResource" :label="$t('editReservationDialog.type.title')" prop="type">
        <BaseElSelect
          v-model="formData.type"
          value-key="id"
          :placeholder="$t('common.select.placeholder')"
          :no-data-text="$t('common.table.empty.text')"
          :disabled="disableFields"
          @change="serviceTypeChange()"
        >
          <BaseElSelectOption
            v-for="item in serviceTypeConfig"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showHumanService" :label="$t('orderDetailBlock.serviceUnit.title')" prop="serviceUnit">
        <ServicesUnitSelect
          :model.sync="formData.serviceUnit"
          :disabled="disableFields"
          @change="serviceUnitChange(formData.serviceUnit), (formData.totalTime = 0)"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderDetailBlock.serviceName.title')" prop="service">
        <!-- <UnitServicesSelect
          showAll
          :unitId="formData.serviceUnit?.id"
          :model.sync="formData.service"
          @change="serviceChange"
        /> -->
        <BaseElSelect
          v-model="formData.service"
          value-key="id"
          filterable
          clearable
          :disabled="disableFields"
          @change="serviceChange"
          @clear="serviceChange"
        >
          <BaseElSelectOption
            v-for="service in filterServiceOptions"
            :key="service.id"
            :value="service"
            :label="service.name"
          />
        </BaseElSelect>
        <p v-if="classTicketUnavailable && data.isUseClassTicket" class="text-gray-80 text-sub">
          {{ $t('editReservationDialog.classTicketUnavailable.title') }}
        </p>
      </BaseElFormItem>
      <BaseElFormItem v-if="enableSubService" :label="$t('orderDetailBlock.subServiceName.title')" prop="subService">
        <BaseElSelect v-model="formData.subService" value-key="id" :disabled="disableFields" @change="subServiceChange">
          <BaseElSelectOption
            v-for="service in subServiceList"
            :key="service.id"
            :label="service.name"
            :value="service"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('orderDetailBlock.attachService.title')" prop="attachServices">
        <AttachServiceSelect
          :model.sync="formData.attachServices"
          :services="formData.service"
          multiple
          :disabled="disableFields"
          @change="attachServiceChange"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="showResourceService && checkResource" :label="$t('orderDetailBlock.resource.title')" prop="resourceItem">
        <ResourceUnitSelect data-cy="unit-binding-select" :model.sync="formData.resourceItem" :data="mainServiceData.ResourceItems" :showAll="true" :disabled="disableFields" />
      </BaseElFormItem>
      <BaseElFormItem v-if="showControl.formItem.bookingTime" :label="$t('editReservationDialog.totalTime.title')" prop="totalTime">
        <BaseElSelect
          v-model="formData.totalTime"
          :placeholder="$t('common.select.placeholder')"
          :disabled="disableFields"
          @change="totalTimeChange"
        >
          <BaseElSelectOption
            v-for="(item, index) in genNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showControl.formItem.resourceBookingTime && checkResource" :label="$t('editReservationDialog.resourceTotalTime.title')" prop="resourceTotalTime">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>{{ $t('editReservationDialog.resourceTotalTime.title') }}</p>
            <el-tooltip v-show="showControl.tipInfo.resourceBookingTime" placement="right">
              <div slot="content">
                {{ $t('editReservationDialog.resourceTotalTime.tooltip') }}
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <div
          v-if="formData.type === 'humanAndResourceService' && !formData.totalTime"
          class="text-gray-100"
        >
          {{ $t('editReservationDialog.selectTotalTimeFirst.title') }}
        </div>
        <BaseElSelect
          v-else
          v-model="formData.resourceTotalTime"
          data-cy="total-time-select"
          :placeholder="$t('common.select.placeholder')"
          :disabled="disableFields"
        >
          <BaseElSelectOption
            v-for="(item, index) in getResourceNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('editReservationDialog.isOverTime.title')">
        <BaseElRadioGroup
          v-model="formData.isOverTime"
          :disabled="disableFields"
          @change=";(formData.time = ''), (formData.date = '')"
        >
          <BaseElRadio :label="true">{{ $t('common.yes.text') }}</BaseElRadio>
          <BaseElRadio :label="false">{{ $t('common.no.text') }}</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('editReservationDialog.date.title')" prop="date">
        <div v-if="!formData.type || formData.service === ''">
          {{ $t('editReservationDialog.selectTypeFirst.title') }}
        </div>
        <el-date-picker
          v-if="formData.service !== ''"
          ref="picker"
          v-model="formData.date"
          editable
          :picker-options="pickerOptions"
          type="date"
          :placeholder="$t('editReservationDialog.date.placeholder')"
          :disabled="disableFields"
          @focus="getDate"
          @change="dateChange"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('editReservationDialog.time.title')" prop="time">
        <div v-if="!formData.date">{{ $t('editReservationDialog.selectDateFirst.title') }}</div>
        <AvailableTimeSelect
          v-if="formData.date && !formData.isOverTime"
          :model.sync="formData.time"
          :timesList="availableTimes"
          :disabled="disableFields"
        />

        <BaseElSelect
          v-if="formData.date && formData.isOverTime"
          v-model="formData.time"
          placeholder="請選擇"
          no-data-text="無數據"
          :disabled="disableFields"
        >
          <BaseElSelectOption
            v-for="(item, index) in genTimes()"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <!-- <BaseElFormItem :label="$t('editReservationDialog.comment.title')" prop="comment">
        <BaseElInput v-model="formData.comment" :placeholder="$t('editReservationDialog.comment.placeholder')" />
      </BaseElFormItem> -->
      <!-- <BaseElFormItem :label="$t('editReservationDialog.remark.title')" prop="remark">
        <BaseElInput v-model="formData.remark" :placeholder="$t('editReservationDialog.remark.placeholder')" />
      </BaseElFormItem> -->
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text') }}</BaseElButton>
      <BaseElButton
        ref="createBtn"
        v-loading="loading"
        type="primary"
        @click="updateInfoAndTime"
      >
        {{ $t('common.button.confirm.text') }}
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import { UpdateReservationTimeServices, UpdateReservationInfo, GetAvailableTimes } from '@/api/reservation'
import AvailableTimeSelect from '@/components/Reservation/AvailableTimeSelect/AvailableTimeSelect.vue'
import dayjs from '@/lib/dayjs'
import { get, filter, map, find, omit, includes, compact, isEmpty } from 'lodash'
import debounce from 'lodash.debounce'
import { noEmptyRules, phoneRules } from '@/validation'
import formUtils from '@/utils/form'
import { generateTimes, generateNumbers, sortOrder } from '@/utils/helper'
// import { GetAvailableTimeByTotalBookingTime } from '@/api/serviceUnit'
import { getMonth, thisMonth, isInvalidDate } from '@/utils/date'
import { noSpecifyUnitOrderOptionConfig, serviceTypeConfig } from '@/config/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { FindService, GetService } from '@/api/service'
import { GetResourceItem } from '@/api/resource'
import { FindServiceUnit } from '@/api/serviceUnit'

export default defineComponent({
  name: 'EditReservationDialog',
  components: {
    // UnitServicesSelect,
    ServicesUnitSelect,
    AttachServiceSelect,
    AvailableTimeSelect,
    ResourceUnitSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    show: Boolean,
    width: String,
    member: Object,
    usingClassTickets: { type: Array, default: () => ([]) },
    isPubApt: Boolean,
    disableFields: Boolean,
  },

  setup (props, { emit }) {
    const loading = ref(false)
    const classTicketUnavailable = ref(false)
    const formData = reactive({
      isMember: false,
      member: {
        UserInfo: {
          name: '',
          phone: '',
        },
      },
      isOverTime: false,
      serviceUnit: '',
      service: '',
      subService: null,
      attachServices: [],
      date: '',
      time: '',
      totalTime: 0,
      comment: '',
      remark: '',
      serviceTime: 0,
      attachServiceTimes: 0,
      userName: '',
      userPhone: '',
      type: 'humanService',
      resourceItem: {},
      resourceTotalTime: 0,
    })
    const checkClassTicketStillCanUse = (service, classTicketId) => {
      const serviceClassTickets = map(service.ClassTickets, 'id')
      return serviceClassTickets.includes(classTicketId)
    }
    const genNums = ref([])
    const getResourceNums = ref([])

    const showControl = computed(() => {
      const controls = {
        formItem: {
          bookingTime: true,
          resourceBookingTime: false,
        },
        tipInfo: {
          resourceBookingTime: false,
        },
      }

      if (formData.type === 'resourceService') {
        controls.formItem.resourceBookingTime = true
        controls.formItem.bookingTime = false
      } else if (formData.type === 'humanAndResourceService') {
        controls.formItem.resourceBookingTime = true
        controls.formItem.bookingTime = true
      }

      if (formData.type === 'humanAndResourceService') {
        controls.tipInfo.resourceBookingTime = true
      }

      return controls
    })

    // TODO 檢查目前堂票是否適用新服務
    return { formData, showControl, classTicketUnavailable, checkClassTicketStillCanUse, genNums, getResourceNums }
  },

  data () {
    return {
      loading: false,
      availableTimes: [],
      availableDate: [],

      formRules: {
        // member: noEmptyRules(),
        // userName: noEmptyRules(),
        type: noEmptyRules,
        userPhone: [noEmptyRules(), phoneRules()],
        name: noEmptyRules(),
        serviceUnit: noEmptyRules(),
        service: noEmptyRules(),
        subService: noEmptyRules(),
        date: noEmptyRules(),
        time: noEmptyRules(),
        resourceItem: noEmptyRules(),
      },

      pickerAddEvent: false,
      pickerOptions: {
        disabledDate: (date) => {
          if (this.formData.isOverTime) return false
          const d = dayjs(date).format('YYYY/MM/DD')
          return !this.availableDate.find((item) => item === d)
        },
      },
      serviceTypeConfig,
      serviceOptions: [],
      mainServiceData: null,
      allResourceItems: [],
    }
  },
  computed: {
    ...mapGetters([
      'shop',
      'shopTimeUnit',
      'userFeatures',
      'userPlanFeature']),
    picker () {
      return this.$refs.picker
    },
    //= > 服務時數 + 附加服務時數
    totalTime () {
      const form = this.formData
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let attach = 0
      if (form.attachServices) {
        form.attachServices.forEach((item) => {
          attach += item.bookingTime
        })
      }

      return base + attach
    },
    totalResourceTime () {
      const form = this.formData
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let attach = 0
      if (form.attachServices) {
        const attachServiceSettings = get(form.service, 'attachServiceSettings', [])
        form.attachServices.forEach((item) => {
          const enableResourceItemBooking = get(find(attachServiceSettings, {
            appointmentServiceAttachId: get(item, 'id'),
          }), 'enableResourceItemBooking')
          if (enableResourceItemBooking) attach += item.bookingTime
        })
      }

      return base + attach
    },
    ohbotBookingId () {
      const ids = []
      const OhbookBookingId = get(this.data, 'OhbookBookingId')
      const ResourceItemOhbookBookingId = get(this.data, 'ResourceItemOhbookBookingId')
      if (OhbookBookingId) ids.push(OhbookBookingId)
      if (ResourceItemOhbookBookingId) ids.push(ResourceItemOhbookBookingId)
      return ids
    },
    attachServicesList () {
      return map(this.formData.attachServices, 'id')
    },
    subServiceList () {
      if (!this.mainServiceData) return []
      else if (get(this.formData, 'type') === 'resourceService') return get(this.mainServiceData, 'AppointmentSubServices')
      else if (get(this.formDatam, 'serviceUnit') && includes(['notSpecify', 'all'], get(this.formData, 'serviceUnit.id'))) return get(this.mainServiceData, 'AppointmentSubServices')
      else if (get(this.formData, 'serviceUnit')) return get(this.mainServiceData, 'AppointmentSubServices').filter(s => find(s.AppointmentUnits, { id: get(this.formData, 'serviceUnit.id') }) || isEmpty(s.AppointmentUnits))
      else return []
    },
    enableSubService () {
      return get(this.formData.service, 'enableSubService')
    },
    checkResource () {
      return this.checkAction('admin.resourceItem.page')
    },
    // showServiceTypeConfig () {
    //   const omitList = []
    //   if (!this.checkAction('admin.resourceItem.page')) omitList.push('resourceService', 'humanAndResourceService')
    //   return omit(this.serviceTypeConfig, omitList)
    // },
    showHumanService () {
      return this.formData.type === 'humanService' || this.formData.type === 'humanAndResourceService'
    },
    showHumanAndResourceService () {
      return this.formData.type === 'humanAndResourceService'
    },
    filterServiceOptions () {
      let serviceOptionList = []
      if (this.checkResource) {
        if (this.formData.type === 'resourceService') {
          serviceOptionList = filter(this.serviceOptions, (i) => !i.enableAppointmentUnit && i.enableResourceItem)
        } else if (this.formData.type === 'humanService') {
          serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
        } else if (this.formData.type === 'humanAndResourceService') {
          serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && i.enableResourceItem)
        } else {
          serviceOptionList = this.serviceOptions
        }
      } else {
        serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
      }
      return serviceOptionList
    },
    showResourceService () {
      return includes(['resourceService', 'humanAndResourceService'], this.formData.type)
    },
  },

  watch: {
    'formData.service': function () {
      this.formData.date = ''
    },

    'formData.serviceUnit': async function () {
      this.formData.date = ''
    },
    'formData.type': async function (data) {
      console.log(data)
      if (data === 'resourceService') this.formRules.serviceUnit = null
      else this.formRules.serviceUnit = noEmptyRules()
    },
  },

  async mounted () {
    await this.getShopReservationConfig({ shopId: this.shop })
    await this.findService(this.data.AppointmentService.id)
    if (this.checkResource) await this.getResourceItem()
    await this.syncData()
    this.updateTimeOptions()
    if (this.formData.serviceUnit) this.findServiceUnit(this.formData.serviceUnit.id)
    else this.getService()
  },

  methods: {
    async syncData () {
      const AppointmentVisitorPreOrder = get(this.data, 'AppointmentOrder.AppointmentVisitorPreOrder')
      this.formData.userName = this.isPubApt ? get(AppointmentVisitorPreOrder, 'profile.lastName') + get(AppointmentVisitorPreOrder, 'profile.firstName') : this.data.userName
      this.formData.userPhone = this.isPubApt ? `${get(AppointmentVisitorPreOrder, 'profile.phoneCountryCode')} ${get(AppointmentVisitorPreOrder, 'profile.phoneNumber')}` : this.data.userPhone

      this.formData.serviceUnit = this.data.AppointmentUnit
      this.formData.resourceItem = this.allResourceItems.find(item => item.id === this.data.ResourceItemId)
      this.formData.attachServices = this.data.AppointmentServiceAttaches
      this.formData.subService = this.data.AppointmentSubService

      this.formData.totalTime = this.data.totalBookingTime

      this.formData.resourceTotalTime = this.data.resourceItemTotalBookingTime
      this.formData.isOverTime = this.data.isOvertime
      setTimeout(() => {
        this.formData.date = this.data.start
        this.formData.time = dayjs(this.data.start).format('HH:mm')
      }, 0)
      if (this.data.ResourceItem) {
        this.formData.type = 'resourceService'
        this.formRules.serviceUnit = null
      }
      if (this.data.AppointmentUnit) {
        this.formData.type = 'humanService'
      }
      if (this.data.ResourceItem && this.data.AppointmentUnit) {
        this.formData.type = 'humanAndResourceService'
      }
      const start = dayjs(this.data.start).startOf('d')
      const end = dayjs(this.data.start).add(1, 'd').startOf('d')
      await this.getAvailableTimes({
        start: dayjs(start),
        end: dayjs(end),
      })
    },
    async getResourceItem  () {
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
      }

      const [res, err] = await GetResourceItem(payload)
      if (err) {
        window.$message.warning(err)
        return
      }
      this.allResourceItems = res
    },
    async getService (query = null) {
      try {
        this.serviceOptions = []
        setTimeout(async () => {
          const res = await GetService({
            shopId: this.shop,
            name: (query) || undefined,
            limit: 50,
          })
          this.serviceOptions = res
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    getNumsByValue (value) {
      return generateNumbers(0, value + this.shopTimeUnit, this.shopTimeUnit)
    },
    debounce,
    ...mapActions('shop', [
      'getShopReservationConfig',
    ]),
    async getAvailableTimes ({ start, end }) {
      let appointmentUnitIds = get(this.formData, 'serviceUnit.id') ? [get(this.formData, 'serviceUnit.id')] : null
      // const useNotSpecify = get(this.configData, 'useNotSpecify')
      if (appointmentUnitIds) {
        if (appointmentUnitIds.includes('all')) appointmentUnitIds = null
        else if (appointmentUnitIds.includes('notSpecify')) appointmentUnitIds = map(get(this.config, 'reservation.NotSpecifyUnits'), 'id')
      }
      console.log('NotSpecifyUnits', get(this.config, 'reservation.NotSpecifyUnits'))

      const resourceItemIds = [get(this.formData, 'resourceItem.id')]

      const [res] = await GetAvailableTimes({
        shopId: this.shop,
        rangeStart: start.toDate(),
        rangeEnd: end.toDate(),
        appointmentUnitIds,
        resourceItemIds: isEmpty(compact(resourceItemIds)) ? null : resourceItemIds,
        appointmentServiceId:
          get(this.formData, 'service.id') || undefined,
        appointmentSubServiceId: get(this.formData, 'subService.id'),
        appointmentServiceAttachIds: map(
          get(this.formData, 'attachServices', []),
          'id',
        ),
        peopleCount: this.peopleCount,
        omitOhbookBookingIds: this.ohbotBookingId,
        setTotalBookingTime: appointmentUnitIds ? this.formData.totalTime : undefined,
        setResourceItemTotalBookingTime: isEmpty(compact(resourceItemIds)) ? undefined : this.formData.resourceTotalTime,
      })

      const list = []
      res.times.forEach((item) => {
        list.push(dayjs(item).format('YYYY/MM/DD'))
      })
      this.availableDate = Array.from(new Set(list))
      this.availableTimes = filter(res.times, (item) => {
        return dayjs(item).isSame(this.formData.date, 'date')
      })
      // this.availableTimes = filter(res.times, (item) => {
      //   return dayjs(item).isAfter(dayjs(), 'h')
      // })
    },
    async getDate () {
      this.$nextTick(async () => {
        let dateStart
        let dateEnd
        if (this.$refs.picker.picker) {
          const year = this.$refs.picker.picker.year
          const month = this.$refs.picker.picker.month
          const { start, end } = getMonth(year, month + 1)
          dateStart = start
          dateEnd = end
        } else {
          const { start, end } = thisMonth()
          dateStart = start
          dateEnd = end
        }
        // await this.getAvailableTimeByTotalBookingTime(dateStart, dateEnd)
        await this.getAvailableTimes({ start: dayjs(dateStart), end: dayjs(dateEnd) })
        if (this.pickerAddEvent) return

        const pre = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left',
        )
        const next = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right',
        )
        const preYear = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left',
        )
        const nextYear = document.querySelector(
          '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right',
        )

        const task = async () => {
          this.availableDate = []

          const year = this.$refs.picker.picker.year
          const month = this.$refs.picker.picker.month
          const { start, end } = getMonth(year, month + 1)

          // await this.getAvailableTimeByTotalBookingTime(start, end)
          await this.getAvailableTimes({ start: dayjs(start), end: dayjs(end) })
        }

        pre.addEventListener('click', task)
        next.addEventListener('click', task)
        preYear.addEventListener('click', task)
        nextYear.addEventListener('click', task)
        this.pickerAddEvent = true
      })
    },

    dateChange (date) {
      const start = dayjs(date).startOf('d')
      const end = dayjs(date).add(1, 'd').startOf('d')
      this.formData.time = ''
      if (!start || !end) return
      this.getAvailableTimes({
        start: dayjs(start),
        end: dayjs(end),
      })
    },
    updateTimeOptions () {
      this.genNums = generateNumbers(0, 600, this.shopTimeUnit)
      this.genNums.push(this.totalTime)
      this.genNums = [...new Set(this.genNums.sort((a, b) => a - b))]
      if (this.formData.type === 'humanAndResourceService') {
        this.getResourceNums = generateNumbers(0, this.formData.totalTime + this.shopTimeUnit, this.shopTimeUnit)
        this.getResourceNums.push(this.totalTime)
        this.getResourceNums = [...new Set(filter(this.getResourceNums.sort((a, b) => a - b), (item) => item <= this.formData.totalTime))]
      } else if (this.formData.type === 'resourceService') {
        this.getResourceNums = generateNumbers(0, 600, this.shopTimeUnit)
        this.getResourceNums.push(this.resourceTotalTime)
        this.getResourceNums = [...new Set(this.genNums.sort((a, b) => a - b))]
      }
    },
    totalTimeChange () {
      if (this.formData.isOverTime) return
      this.formData.date = ''
      this.formData.resourceTotalTime = this.formData.totalTime
      this.updateTimeOptions()
    },
    serviceTypeChange () {
      this.formData.otherService = false
      this.formData.serviceUnit = null
      this.formData.serviceDetail = null
      this.formData.service = null
      this.formData.attachServices = null
      this.formData.subService = null
      this.formData.attachServices = []
      this.formData.resourceItem = []
      this.formData.totalTime = 0
      this.formData.resourceTotalTime = 0
      this.formData.isOverTime = false
      this.formData.date = null
      this.formData.time = null
    },

    serviceUnitChange (serviceUnit) {
      console.log('serviceUnitChange')
      this.formData.service = ''
      this.formData.attachServices = ''
      this.findServiceUnit(serviceUnit.id)
      this.updateTimeOptions()
    },
    async findServiceUnit (unitId) {
      if (unitId === '' || unitId === undefined) return
      try {
        const res = await FindServiceUnit({
          shopId: this.shop,
          id: unitId,
        })

        this.serviceOptions = sortOrder(res.AppointmentServices)
      } catch (error) {
        console.log(error)
      }
    },

    async serviceChange () {
      await this.findService(this.formData.service.id)
      this.formData.subService = null
      this.formData.attachServices = []
      this.formData.resourceItem = []
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalResourceTime
      this.classTicketUnavailable = !this.checkClassTicketStillCanUse(this.formData.service, get(this.usingClassTickets, '[0].ClassTicketRecord.ClassTicketId')) && this.checkAction('admin.appointmentReservationTicket.*')
      this.updateTimeOptions()
    },

    subServiceChange (service) {
      this.formData.subService = service
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalResourceTime
      this.updateTimeOptions()
      this.formData.resourceItem = []
      this.formData.attachServices = []
      this.formData.date = ''
      this.formData.time = ''
    },

    attachServiceChange () {
      this.formData.date = ''
      this.formData.time = ''
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalResourceTime
      this.updateTimeOptions()
    },

    genTimes () {
      return generateTimes(this.shopTimeUnit)
    },

    async updateOrderInfo () {
      const [, err] = await UpdateReservationInfo({
        shopId: this.shop,
        id: this.data.id,
        userName: this.formData.userName,
        userPhone: this.formData.userPhone,
      })
      if (err) {
        this.$message.error(err)
        this.loading = false
        return false
      }
    },

    async updateReservationTime () {
      if (!await formUtils.checkForm(this.$refs.form)) {
        this.loading = false
        return false
      }

      const date = dayjs(this.formData.date).format('YYYY/MM/DD')
      let time = dayjs(this.formData.time).format('HH:mm')
      if (isInvalidDate(time)) time = this.formData.time
      const start = dayjs(`${date} ${time}`).toDate()
      let totalBookingTime = this.formData.totalTime
      let resourceItemTotalBookingTime = 0

      if (get(this.formData, 'type') === 'resourceService') {
        totalBookingTime = 0
        resourceItemTotalBookingTime = this.formData.resourceTotalTime
      } else if (get(this.formData, 'type') === 'humanAndResourceService') {
        resourceItemTotalBookingTime = this.formData.resourceTotalTime
      }

      const [, err] = await UpdateReservationTimeServices({
        shopId: this.shop,
        id: this.data.id,
        start,
        resourceItemId: get(this.formData, 'resourceItem.id'),
        appointmentUnitId: get(this.formData, 'serviceUnit.id'),
        appointmentServiceId: get(this.formData, 'service.id'),
        appointmentSubServiceId: get(this.formData, 'subService.id'),
        appointmentServiceAttachIds: this.attachServicesList,
        totalBookingTime: totalBookingTime,
        resourceItemTotalBookingTime: resourceItemTotalBookingTime,
        overtime: this.formData.isOverTime,
      })

      if (err) {
        this.$message.error(err)
        this.loading = false
        return false
      }
      return true
    },

    updateInfoAndTime: debounce(async function () {
      if (this.loading) return
      this.loading = true

      if (!await this.updateReservationTime()) return
      await this.updateOrderInfo()

      this.$message({
        message: '更新成功',
        type: 'success',
      })
      this.$emit('refresh')
      this.$emit('close')
      this.loading = false
    }, 300, { leading: false, trailing: true }),

    async findService (id) {
      const [res, err] = await FindService({
        shopId: this.shop,
        id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.formData.service = res
      this.mainServiceData = res
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
  margin-bottom:  22px !important;
}
</style>
