<template>
  <div class="">
    <p class="section-title text-primary-100 font-medium">{{ displayTitle }}</p>
    <BaseElForm label-position="left" label-width="150px">
      <!-- <BaseElFormItem label="付款狀態">
        <div class="inline-flex items-center gap-[8px]">
          <Tag style="display: inline-flex" :type="displayData.status.tagType" disable-transitions>
            {{ displayData.status.name }}
          </Tag>
          <BaseElButton
            v-if="showRefundBtn"
            type="text"
            class="refund-btn underline"
            @click="modal.refund = true"
          >
            前往退款
          </BaseElButton>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="實付金額"> {{ displayData.checkoutPrice }} </BaseElFormItem>
      <BaseElFormItem label="付款方式"> {{ displayData.paidType }} </BaseElFormItem>
      <BaseElFormItem label="交易序號"> {{ displayData.orderCode }} </BaseElFormItem> -->

      <BaseElFormItem v-for="struct in dataStruct" :key="struct.key" :label="struct.label">
        <div v-if="struct.items" class="flex items-center" :style="`gap: ${struct.itemsGap}px; min-height: 40px`">
          <template v-for="(item, idx) in struct.items">
            <p v-if="item.el === 'p'" :key="`item-${idx}`">
              {{ item.label }}
            </p>
            <Tag
              v-if="item.el === 'tag'"
              :key="`item-${idx}`"
              v-bind="item.props"
            >
              {{ item.label }}
            </Tag>

            <div
              v-if="item.el === 'materialIcon'"
              :key="`item-${idx}`"
              class="cursor-pointer"
              v-on="item.on"
            >
              <MaterialIcon color="var(--primary-100)" size="24">
                {{ item.icon }}
              </MaterialIcon>
            </div>

            <BaseElButton
              v-if="item.el === 'el-button' && item.if"
              :key="`item-${idx}`"
              v-bind="item.props"
              v-on="item.on"
            >
              {{ item.label }}
            </BaseElButton>
          </template>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <MarkRefundDialog
      v-if="modal.refund"
      :orderData="orderData"
      :data="refundData"
      :maxPrice="checkoutPrice || null"
      chargeType="checkout"
      @marked="$emit('refresh')"
      @close="modal.refund = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import { get, includes, filter } from 'lodash'
import Tag from '@/components/Tag/Tag.vue'
import { paymentStatusConfig, paymentOptionsConfig } from '@/config/payment'
import MarkRefundDialog from './MarkRefundDialog.vue'
import copy from 'clipboard-copy'

import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'BatchPaymentBlock',
  components: { Tag, MarkRefundDialog },
  props: {
    reservation: { type: Object, default: () => ({}) },
    chargeType: { type: String },
  },
  setup (props) {
    const modal = reactive({
      refund: false,
    })

    const displayTitle = computed(() => {
      if (props.chargeType === 'checkout') {
        return '合併付款資訊'
      } else if (props.chargeType === 'deposit') {
        return '合併訂金付款資訊'
      }
      return '合併付款資訊'
    })

    const chargeType = computed(() => {
      return get(props.reservation, 'AppointmentOrder.AppointmentBatchOrder.chargeType')
    })

    const orderData = computed(() => {
      return get(props.reservation, 'AppointmentOrder') || {}
    })

    const checkoutPrice = computed(() => {
      return get(props.reservation, 'AppointmentOrder.AppointmentBatchOrder.price')
    })

    const refundData = computed(() => {
      let paidType
      let paidTypeComment
      if (chargeType.value === 'checkout') {
        paidType =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
          )
        paidTypeComment =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
          )
      } else if (chargeType.value === 'deposit') {
        paidType =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentOrderDeposit.paidType',
          )
        paidTypeComment =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentOrderDeposit.paidTypeComment',
          )
      }
      return {
        refundType: paidType,
        refundTypeComment: paidTypeComment,
      }
    })

    const showRefundBtn = computed(() => {
      // let status
      // if (chargeType.value === 'deposit') {
      //   status = get(
      //     props.reservation,
      //     'AppointmentOrder.AppointmentOrderDeposit.status',
      //   )
      // } else if (chargeType.value === 'checkout') {
      //   status = get(
      //     props.reservation,
      //     'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.status',
      //   )
      // }
      // return status === 'waitRefund'
      const batchOrderPaymentStatus = get(
        props.reservation,
        'AppointmentOrder.AppointmentBatchOrder.StandardPaymentOrder.status',
      )
      const refundRequired = get(
        props.reservation,
        'AppointmentOrder.AppointmentBatchOrder.StandardPaymentOrder.refundRequired',
      )
      return batchOrderPaymentStatus === 'paid' && refundRequired
    })

    const displayData = computed(() => {
      const status = get(
        props.reservation,
        'AppointmentOrder.AppointmentBatchOrder.StandardPaymentOrder.status',
      )
      const checkoutPrice = get(
        props.reservation,
        'AppointmentOrder.AppointmentBatchOrder.price',
      )

      let displayPaidType
      displayPaidType =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentBatchOrder.StandardPaymentOrder.paidTypeInfo.code',
        )

      if (displayPaidType === 'offline') {
        displayPaidType =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentBatchOrder.StandardPaymentOrder.paidTypeInfo.code',
          )
      }

      return {
        status: get(paymentStatusConfig, status) || { name: '-', tagType: 'info' },
        checkoutPrice: checkoutPrice ? `$ ${checkoutPrice}` : '-',
        paidType: displayPaidType === 'free' ? '免付款' : get(paymentOptionsConfig, `${displayPaidType}.name`) || '-',
        orderCode: get(props.reservation, 'AppointmentOrder.AppointmentBatchOrder.StandardPaymentOrder.code') || '-',
      }
    })

    const dataStruct = computed(() => {
      // 所有欄位與資料
      const data = [
        {
          label: '付款狀態',
          key: 'status',
          itemsGap: 8,
          items: [
            {
              el: 'tag',
              props: {
                type: displayData.value.status.tagType,
                disableTransitions: true,
              },
              label: displayData.value.status.name,
            },
            {
              el: 'el-button',
              if: showRefundBtn.value,
              props: { type: 'text', class: 'refund-btn underline' },
              on: {
                click: () => {
                  modal.refund = true
                },
              },
              label: '前往退款',
            },
          ],
        },
        {
          label: '實付金額',
          key: 'checkoutPrice',
          items: [
            { el: 'p', label: displayData.value.checkoutPrice },
          ],
        },
        {
          label: '付款方式',
          key: 'paidType',
          items: [
            { el: 'p', label: displayData.value.paidType },
          ],
        },
        {
          label: '交易序號',
          key: 'orderCode',
          itemsGap: 8,
          items: [
            {
              el: 'p',
              label: displayData.value.orderCode,
            },
            {
              el: 'materialIcon',
              icon: 'content_copy_filled',
              on: {
                click: () => {
                  try {
                    copy(displayData.value.orderCode)
                    window.$message.success(notifyMessage.copySuccess)
                  } catch (error) {
                    window.$message.error(error)
                  }
                },
              },
            },
          ],
        },
      ]
      return data
    })

    return { displayData, modal, showRefundBtn, refundData, orderData, checkoutPrice, dataStruct, displayTitle }
  },
})
</script>

<style lang="postcss" scoped></style>
