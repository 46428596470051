export const currencyOptions = {
  TWD: { label: 'NT$ TWD', value: 'TWD', title: 'common.currencyOptions.TWD', symbol: 'NT$' },
  JPY: { label: '¥ JPY', value: 'JPY', title: 'common.currencyOptions.JPY', symbol: '¥' },
  KRW: { label: '₩ KRW', value: 'KRW', title: 'common.currencyOptions.KRW', symbol: '₩' },
  USD: { label: '$ USD', value: 'USD', title: 'common.currencyOptions.USD', symbol: '$' },
}

export const timezoneOptions = {
  'Asia/Taipei': { label: 'Asia/Taipei (UTC+08:00)', value: 'Asia/Taipei' },
  'Asia/Tokyo': { label: 'Asia/Tokyo (UTC+09:00)', value: 'Asia/Tokyo' },
  'Asia/Seoul': { label: 'Asia/Seoul (UTC+09:00)', value: 'Asia/Seoul' },
  'Asia/Shanghai': { label: 'Asia/Shanghai (UTC+08:00)', value: 'Asia/Shanghai' },
  'Asia/Hong_Kong': { label: 'Asia/Hong_Kong (UTC+08:00)', value: 'Asia/Hong_Kong' },
  'America/New_York': { label: 'America/New_York (UTC-05:00)', value: 'America/New_York' },
  'America/Chicago': { label: 'America/Chicago (UTC-06:00)', value: 'America/Chicago' },
  'America/Denver': { label: 'America/Denver (UTC-07:00)', value: 'America/Denver' },
  'America/Los_Angeles': { label: 'America/Los_Angeles (UTC-08:00)', value: 'America/Los_Angeles' },
  'America/Toronto': { label: 'America/Toronto (UTC-05:00)', value: 'America/Toronto' },
  'Europe/London': { label: 'Europe/London (UTC+00:00)', value: 'Europe/London' },
  'Europe/Paris': { label: 'Europe/Paris (UTC+01:00)', value: 'Europe/Paris' },
  'Europe/Berlin': { label: 'Europe/Berlin (UTC+01:00)', value: 'Europe/Berlin' },
  'Australia/Sydney': { label: 'Australia/Sydney (UTC+10:00)', value: 'Australia/Sydney' },
  'Pacific/Auckland': { label: 'Pacific/Auckland (UTC+12:00)', value: 'Pacific/Auckland' },
}
