<template>
  <BaseDialog
    :title="displayData.dialogTitle"
    :btn1-loading="loading"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @confirm="markRefund"
  >
    <div style="padding-bottom: 40px;">
      <BaseElForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
        <BaseElFormItem :label="displayData.formItemLabel.markPrice" prop="markPrice">
          <BaseElInput v-model="formData.markPrice" type="number" placeholder="請輸入金額" />
        </BaseElFormItem>
      </BaseElForm>
      <div class="hint" style="width: 560px">
        {{ $t('markRefundDialog.markPrice.hint.title') }}
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'
import { OrderDepositRefund, OrderCheckoutRefund, AppointmentBatchOrderPaymentRefund } from '@/api/reservation'
import { batchManualRefundPaymentType, manualRefundPaymentType } from '@/config/deposit'
import { get } from 'lodash'
import { computed, defineComponent, reactive, ref } from 'vue'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'MarkRefundDialog',
  components: { BaseDialog },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    depositData: {
      type: Object,
      default: () => ({}),
    },
    data: { type: Object, default: () => ({}) },
    chargeType: String,
    maxPrice: Number,
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const formRef = ref(null)
    const loading = ref(false)
    const formData = reactive({
      markPrice: null,
    })
    const formRules = computed(() => {
      const rules = {
        markPrice: [noEmptyRules(), isDigitRules()],
      }
      if (props.maxPrice) {
        rules.markPrice.push(rangeRules(1, props.maxPrice))
      }
      return rules
    })

    const orderType = computed(() => {
      if (get(props.orderData, 'AppointmentBatchOrderId')) {
        return 'batch'
      }
      return 'single'
    })

    const displayData = computed(() => {
      const data = {
        dialogTitle: i18n.t('orderPaymentBlock.dialog.markRefund.title'),
        formItemLabel: {
          markPrice: i18n.t('markRefundDialog.markPrice.title'),
        },
      }

      if (props.data.refundType === 'wallet') {
        data.formItemLabel.markPrice = i18n.t('orderPaymentBlock.walletPrice.title')
        data.dialogTitle = i18n.t('orderPaymentBlock.dialog.refund.title')
      }

      return data
    })

    const showControl = computed(() => {
      const controls = {
        hint: false,
      }
      return controls
    })

    // ?? API 退款提交資料
    const submitPayload = computed(() => {
      const paidType = get(props.data, 'refundType')
      const comment = get(props.data, 'refundTypeComment')
      let type
      if (orderType.value === 'single') type = get(manualRefundPaymentType[paidType], 'value')
      else if (orderType.value === 'batch') type = get(batchManualRefundPaymentType[paidType], 'value') || 'manual'

      const payload = {
        shopId: shopId.value,
        id: props.orderData.id,
        refundType: type,
        refundTypeComment: comment || undefined,
        refundPrice: formData.markPrice,
        refundTypeInfo: {
          code: paidType,
          comment: comment || undefined,
          price: formData.markPrice,
        },
      }

      if (orderType.value === 'single') {
        delete payload.refundTypeInfo
      } else if (orderType.value === 'batch') {
        payload.id = get(props.orderData, 'AppointmentBatchOrderId')
        delete payload.refundTypeComment
        delete payload.refundPrice
      }

      return payload
    })

    // => 執行退款
    const markRefund = async () => {
      if (!(await formUtils.checkForm(formRef.value))) return

      let apiMethod

      if (orderType.value === 'single') {
        if (props.chargeType === 'deposit') {
          apiMethod = OrderDepositRefund
        } else if (props.chargeType === 'checkout') {
          apiMethod = OrderCheckoutRefund
        }
      } else if (orderType.value === 'batch') {
        apiMethod = AppointmentBatchOrderPaymentRefund
      }

      loading.value = true
      const [, err] = await apiMethod(submitPayload.value)
      loading.value = false

      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(i18n.t('common.message.markRefundSuccess'))
      emit('marked')
      emit('close')
    }

    return {
      loading,
      formRef,
      formData,
      formRules,
      markRefund,
      displayData,
    }
  },
})

</script>

<style lang="postcss" scoped>
.hint {
  @apply text-danger break-all whitespace-normal text-sm w-auto leading-[17.38px] mt-[12px];
}
</style>
